<template>
  <!-- FORM -->
  <!-- USERSIGNUP -->
  <form
    id="create-account"
    ref="signup-form"
    class="tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/signup"
    @submit.prevent="onSubmit"
    v-if="accountType"
  >

    <UserSignUp @input="updateForm" :title="formTitle" />

    <!-- LICENSE INPUTS -->
    <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <h2 class="font-title tw-text-xl tw-mb-1 tw-text-left">2. Company/License details</h2>
    </div>
    <template v-if="isSuperAdmin">

      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="License Type"
          name="license"
          nameKey="license_type"
          placeholder="License Type"
          input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
          v-model="license"
          :options="licenseList"
          :reduce="license => license.id"
          :selected="license"
          :clearable="false"
          :error="errors.license"
        />
      </div>
      <div
        :class="[
          'tw-mb-4 tw-flex-shrink-0 tw-px-1',
          { 'tw-w-2/3 ': isSuperAdmin },
          { 'tw-w-full ': !isSuperAdmin },
        ]"
      >
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="Company"
          name="company"
          nameKey="company"
          placeholder="Company"
          input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
          v-model="company"
          :options="companyList"
          :reduce="company => company.id"
          :selected="company"
          :clearable="false"
          :error="errors.company"
        />
      </div>
      <div v-if="isSuperAdmin" class="tw-1/3 tw-mb-4 tw-px-1 tw-self-end">
        <!-- CREATE COMPANY LINK -->
        <BaseButton
          class="tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8"
          to="/app/company?create"
          text="Create a company"
        />
      </div>

    </template>
    <template v-else>
      <!-- show default license/ company -->

      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1 tw-relative tw-block tw-text-left">
        <BaseCard
          class="tw-p-4 tw-inline-flex tw-justify-start tw-self-start tw-items-center tw-bg-app-yellow"
        >
          <UserPhoto
            :photo="myCompany.logo"
            :user-name="{
              name: myCompany.company,
            }"
            photo-size="tw-h-12 tw-w-12"
            logo
            class="tw-shadow-app-sm tw-rounded-10--force --force-children tw-bg-app-white-87"
          />

          <div class="tw-mx-8 tw-text-base">
            <h4 class="font-nunito" >{{ myCompany.company }}</h4>
            <p class="tw-text-xs opacity-54">{{ myCompany.address }}</p>

          </div>

        </BaseCard>
      </div>

    </template>

    <template v-if="isStaffAccount">
      <!-- ask if is staff mentee/mentor -->
      <div class="tw-w-full tw-pt-16 tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-text-left">
          3. {{ (isStaffMenteeAccount ? 'Mentee' : (isStaffMentorAccount ? 'Mentor' : 'User')) }} details
        </h2>
        <div>
          <BaseCheckBox
            @change="selectStaffAccountType(arguments)"
            :checked="accountType2 === accountTypes.staff"
            :id="accountTypes.staff"
            :label="`Admin ${userForm.fname || ''} is neither a mentor nor a mentee`"
            :value="accountTypes.staff"
            class="tw-text-sm"
          />

          <BaseCheckBox
            @change="selectStaffAccountType(arguments)"
            :checked="accountType2 === accountTypes.mentor"
            :id="accountTypes.mentor"
            :label="`Admin ${userForm.fname || ''} is a mentor`"
            :value="accountTypes.mentor"
            class="tw-text-sm"
          />
          <BaseCheckBox
            @change="selectStaffAccountType(arguments)"
            :checked="accountType2 === accountTypes.mentee"
            :id="accountTypes.mentee"
            :label="`Admin ${userForm.fname || ''} is a mentee`"
            :value="accountTypes.mentee"
            class="tw-text-sm"
          />
        </div>
      </div>
    </template>

    <!-- EXTRA FORM USERTYPE SPECIFIC INPUTS -->
    <template v-if="isMentorAccount || isStaffMentorAccount">
      <div class="tw-w-full tw-pt-16 tw-mb-4 tw-flex-shrink-0 tw-px-1" v-if="!isStaffMentorAccount">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-text-left">3. Mentor details</h2>
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="Profession"
          name="profession"
          nameKey="profession"
          placeholder="Profession"
          input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
          v-model="profession"
          :options="professionList"
          :reduce="profession => profession.id"
          :selected="profession"
          :clearable="false"
          :error="errors.profession"
        />
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="Experience (years)"
          name="experience"
          nameKey="experience"
          placeholder="Experience"
          input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1 tw-text-left"
          v-model="experience"
          :options="experienceList"
          :reduce="experience => experience.id"
          :selected="experience"
          :clearable="false"
          :error="errors.experience"
        />
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="Availability"
          name="availability"
          instruct="You can add multiple values"
          nameKey="availability"
          placeholder="Availability"
          input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1 tw-text-left"
          v-model="availability"
          :options="availabilityList"
          :reduce="availability => availability.id"
          :multiple="true"
          :clearable="true"
          :error="errors.availability"
        />
      </div>
    </template>

    <template v-else-if="isMenteeAccount || isStaffMenteeAccount">
      <div class="tw-w-full tw-pt-16 tw-mb-4 tw-flex-shrink-0 tw-px-1" v-if="!isStaffMenteeAccount">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-text-left">3. Mentee details</h2>
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="Mentee category"
          name="goal"
          nameKey="goal_group"
          placeholder="Goal"
          input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
          v-model="profession"
          :options="professionList"
          :reduce="profession => profession.id"
          :selected="profession"
          :clearable="false"
          :error="errors.profession"
        />
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="Availability"
          name="availability"
          instruct="You can add multiple values"
          nameKey="availability"
          placeholder="Availability"
          input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1 tw-text-left"
          v-model="availability"
          :options="availabilityList"
          :reduce="availability => availability.id"
          :multiple="true"
          :clearable="true"
          :error="errors.availability"
        />
      </div>
    </template>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-20">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import { mapState } from 'vuex';
import UserSignUp from '@/views/_partials/UserSignUp.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import UserPhoto from '@/components/UserPhoto.vue';
import BaseCheckBox from '@/components/BaseCheckBox.vue';
import form from '@/modules/formHelper';
import { apiGet, apiPost, generateFormData } from '@/modules/apiHelper';
import resolve from '@/modules/api/resolve';

export default {
  name: 'CreateAccount',
  components: {
    UserSignUp,
    SelectGroup,
    UserPhoto,
    BaseCheckBox,
  },
  props: {
    accountType: {
      type: Number,
      required: false,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: false,
      errorForm: false,
      btnText: 'Create',
      btnDisabled: false,
      myCompany: {},

      userForm: {
        // text inputs
        fname: undefined,
        sname: undefined,
        mname: undefined,
        address: undefined,
        // passwords
        password: undefined,
        confirmPassword: undefined,
        // number
        phone: undefined,
        // email
        email: undefined,
        // selects
        gender: undefined,
        county: undefined,
        country: undefined,
      },
      // user specific info
      accountType2: 1, // staffMentee or staffMentor. Used for 'type' in admin
      profession: undefined,
      availability: [],
      availabilityList: [],
      professionList: [],
      license: undefined,
      licenseList: [],
      company: undefined,
      companyList: [],
      // mentor specific form data
      experience: undefined,
      experienceList: [],
      //
      errors: {
        profession: undefined,
        availability: undefined,
        experience: undefined,
        license: undefined,
        company: undefined,
      },
      requests: {},
    };
  },
  watch: {
    async company(val) {
      if (val) {
        this.loadingForm = true;
        await this.$nextTick();
        await this.handle(this.requests.professionList);
        await this.$nextTick();
        this.loadingForm = false;
      }
    },
    profession(val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.profession = 'Mentee category is required';
      } else {
        this.errors.profession = undefined;
      }
    },
    experience(val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.experience = 'Experience is required';
      } else {
        this.errors.experience = undefined;
      }
    },
    async accountType(val) {
      if (val) {
        this.loadingForm = true;
        await this.$nextTick();
        await this.$nextTick();
        this.loadForm();
      }
    },
    async accountType2(val) {
      if (val) {
        this.loadingForm = true;
        await this.$nextTick();
        await this.$nextTick();
        this.loadForm();
      }
    },
  },
  computed: {
    ...mapState({
      accountTypes: (state) => state.User.accountTypes,
    }),
    formTitle() {
      switch (this.accountType) {
        case this.accountTypes.superAdmin:
          return 'Super Admin Signup';
        case this.accountTypes.staff:
          return 'Staff Admin Signup';
        case this.accountTypes.mentor:
          return 'Mentor Signup';
        case this.accountTypes.mentee:
          return 'Mentee Signup';
        default:
          return 'Signup';
      }
    },
    isSuperAdminAccount() {
      return this.accountType === this.accountTypes.superAdmin;
    },
    isStaffAccount() {
      return this.accountType === this.accountTypes.staff;
    },
    isMentorAccount() {
      return this.accountType === this.accountTypes.mentor;
    },
    isMenteeAccount() {
      return this.accountType === this.accountTypes.mentee;
    },
    isStaffMentorAccount() {
      return this.isStaffAccount && this.accountType2 === this.accountTypes.mentor;
    },
    isStaffMenteeAccount() {
      return this.isStaffAccount && this.accountType2 === this.accountTypes.mentee;
    },
    canSubmit() {
      return form.isValidTextInput(this.userForm.fname)
        && form.isValidTextInput(this.userForm.sname)
        && form.isValidEmail(this.userForm.email)
        && form.isValidTextInput(this.userForm.password, true, form.passwordRegex)
        && form.passwordsMatch(this.userForm.password, this.userForm.confirmPassword)
        && form.isValidNumber(this.userForm.phone)
        && form.isValidSelectInput([
          this.userForm.gender,
          this.license,
          this.company,
          (this.isMentorAccount || this.isMenteeAccount) ? this.profession : true,
          this.isMentorAccount ? this.experience : true,
        ])
        && ((this.isMentorAccount || this.isMenteeAccount)
          ? this.availability.length : true);
    },
  },
  methods: {
    updateForm(formData) {
      // this.userForm = { ...formData };
      // eslint-disable-next-line prefer-object-spread
      this.userForm = Object.assign({}, this.userForm, formData);
    },

    formData() {
      const formData = new FormData();

      formData.set('fname', this.userForm.fname);
      formData.set('sname', this.userForm.sname);
      formData.set('working_num', this.userForm.phone);
      formData.set('email', this.userForm.email);
      formData.set('password', this.userForm.password);
      formData.set('gender', this.userForm.gender);
      formData.set('companyid', this.company); // ! API change update to use /process
      formData.set('licenseid', this.license); // ? eh, what happens here
      formData.set('availability', JSON.stringify(this.availability));
      formData.set('profession', this.profession);

      if (!this.isMentorAccount && !this.isMenteeAccount) {
        // * admin/staff only
        formData.set('level', this.accountType);
        formData.set('type', this.accountType2);
      } else {
        // * mentor/mentee only
        formData.set('type', this.accountType);
      }

      if (this.isMentorAccount || this.isStaffMentorAccount) {
        // * mentor/mentor admin only
        formData.set('experience', this.experience);
      }

      return formData;
    },

    selectStaffAccountType([accountType, checked]) {
      this.accountType2 = checked ? accountType : undefined;
    },

    onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      apiPost('create', this.formData(), 12)
        .catch((err) => {
          console.error('create error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnDisabled = false;
          this.btnText = 'create';

          if (!res) return false;

          // eslint-disable-next-line prefer-destructuring
          const { data } = res;

          if (data.error) {
            console.error(data.message);

            this.$toasted.global.appError({
              errorMessage: data.message,
              duration: 4000,
              position: 'top-right',
            });

            return false;
          }

          if (this.isSuperAdminAccount || this.isStaffAccount) {
            // do an add_admin to the new account
            const formData = generateFormData({
              email: this.userForm.email,
              companyid: this.company,
              level: this.accountType,
            });

            const addAdminResponse = await resolve(apiPost('add_admin', formData));

            if (!addAdminResponse) {
              const errorMessage = 'could not add new user to be an admin';
              console.warn(errorMessage);
              this.$toasted.global.appError({ errorMessage });

              return false;
            }
          }

          this.$toasted.info('Success!', {
            position: 'bottom-left',
            fullWidth: true,
            duration: 3300,
          });

          this.$emit('create-success', this.userForm);

          return true;
        });

      //
      return true;
    },

    async prepareFormRequests() {
      const $this = this;
      // eslint-disable-next-line no-multi-assign
      const requests = {
        professionList() {
          if ($this.isMenteeAccount || $this.isStaffMenteeAccount) {
            return [$this.$store.dispatch('getPillarGroups', [$this.company, true])];
          }
          if ($this.isMentorAccount || $this.isStaffMentorAccount) {
            return [apiGet('profession', 5), 'profession'];
          }
          return [false];
        },
        availabilityList() {
          return [apiGet('availability', 5), 'availability'];
        },
        experienceList() {
          return [apiGet('experience', 5), 'experience'];
        },
        licenseList() {
          if (!$this.isSuperAdmin) return [false];
          return [apiGet('license_group'), 'license_group'];
        },
        companyList() {
          if (!$this.isSuperAdmin) return [false];
          return [apiGet('company'), 'company'];
        },
      };

      this.requests = Object.create(requests);

      return requests;
    },

    async loadForm() {
      this.loadingForm = true;
      this.errorForm = undefined;

      const requestsPromises = [];

      await Object.values((await this.prepareFormRequests())).forEach((value) => {
        if (typeof value === 'function') {
          requestsPromises.push(this.handle(value));
        }
      });

      await Promise.all(requestsPromises);

      this.loadingForm = false;
    },

    /**
     * handler for a form's input request during this.loadForm()
     */
    handle(request) {
      // console.log(request.name);
      return new Promise((res, rej) => {
        const r = request();

        if (!r[0]) res(r);

        r[0].catch((err) => {
          console.warn(request.name, err);

          this.$toasted.global.appError({ duration: 10000 });
          this.loadingForm = false;
          this.errorForm = 'error in loading form';

          rej(err);
        })
          .then((result) => {
            if (!result) return res(result);

            if ('data' in result) {
              if (result.data.error) {
                this.errorForm = 'error in loading form';
                return res(result.data);
              }

              this[request.name] = result.data[r[1]] || this[request.name];

              return res(result.data);
            }

            this[request.name] = result || this[request.name];

            res(result);
            return false;
          });
        //
      });
    },
  },
  async created() {
    if (!this.accountType) {
      // don't render form
      return false;
    }

    if (!this.isSuperAdmin) {
      // pre-select the company id if it's a staff admin
      this.myCompany = await this.$store.dispatch('myCompany');

      if (!this.myCompany) {
        this.$toasted.global.appError();
        return false;
      }

      this.company = this.myCompany.id;
      this.license = 3; // ! pre-select cooperate license type
    }

    await this.loadForm();
    return true;
  },
};
</script>

<style>

</style>
