export default {
  name: 'routerMixin',
  methods: {
    routeTo(param) {
      if (param.name || param.length) {
        this.$router.push(param);
      } else {
        console.warn('error in param parsed. Invalid param: ', param);
      }
    },
  },
};
