<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Accounts"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <template v-slot:right-actions>
            <BaseButton
              text="Export"
              :class="[
                'tw-mr-8 transition-fast tw-py-3 tw-px-10 tw-flex-shrink-0 tw-border-none tw-flex-grow-0',
                { 'tw-bg-app-black-11 opacity-31 tw-cursor-default': !hasFiltered },
                { 'tw-bg-blue-200 hover:tw-shadow-app': hasFiltered },
                { 'tw-rounded-full opacity-31 tw-cursor-default': filteredAccounts.length === 0 }
              ]"
              :disabled="!hasFiltered || filteredAccounts.length === 0"
              @click="createReport(filteredAccounts, reportType, `eMentored\nList of ${filterConfig.state} ${filterConfig.type}s`)"
            />
            <BaseButton
              text="Create Account"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mr-body"
              @click="openModal()"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <portal to="modal-header">
      <transition name="new" mode="out-in" appear>
        <div v-if="!createAccountType">
          <h3
            class="tw-text-center tw-text-app-deep-blue tw-text-xl tw-leading-6 font-title tw-uppercase"
          >
            Create Account
          </h3>
          <h5 class="tw-text-center tw-text-sm opacity-87">(Choose an account type)</h5>
        </div>

        <!-- BACK BUTTON -->
        <div v-else style="max-width: 700px;" class="md:tw-w-2/3 tw-mx-auto tw-relative">
          <BaseButton
            class="tw-uppercase tw-rounded-md tw-z-20 hover:tw-bg-gray-300 tw-absolute tw-left-0 tw-top-0 tw-flex tw-items-center"
            text="Back"
            icon
            @click="createAccountType = undefined"
          >
            <span
              class="my-btn__icon tw-h-auto"
              style="height: auto;"
              slot="icon"
              v-html="leftNav"
            ></span>
          </BaseButton>
        </div>
      </transition>
    </portal>

    <portal to="modal-body">
      <transition name="new" mode="out-in" appear>

        <div
          v-if="!createAccountType"
          class="tw-flex tw-max-w-full-- tw-my-12 tw-justify-center tw-flex-wrap"
        >

          <!-- MENTOR -->
          <BaseCard
            style="border-color: #E7D8C9;"
            class="create-account-card tw-rounded-10 tw-flex-grow-0 tw-flex-shrink-0 tw-border-2 border-box tw-px-4 tw-mr-6 tw-cursor-pointer tw-bg-white transition tw-shadow-app-sm hover:tw-shadow-app-lg"
            @click="createAccountType = accountTypes.mentor"
          >
            <div
              class="bg-pattern-mentor tw-w-full"
              style="height: 90px;"
            ></div>
            <h4 class="font-title tw-text-2xl tw-font-medium tw-text-center tw-leading-9 tw-mt-10">Mentor</h4>

          </BaseCard>

          <!-- MENTEE -->
          <BaseCard
            style="border-color: #90A8C3;"
            class="create-account-card tw-rounded-10 tw-flex-grow-0 tw-flex-shrink-0 tw-border-2 border-box tw-px-4 tw-mr-6 tw-cursor-pointer tw-bg-white transition tw-shadow-app-sm hover:tw-shadow-app-lg"
            @click="createAccountType = accountTypes.mentee"
          >
            <div
              class="bg-pattern-mentee tw-w-full"
              style="height: 90px;"
            ></div>
            <h4 class="font-title tw-text-2xl tw-font-medium tw-text-center tw-leading-9 tw-mt-10">Mentee</h4>
          </BaseCard>

          <template v-if="isSuperAdmin">

            <!-- STAFF -->
            <BaseCard
              style="border-color: #98C9A3;"
              class="create-account-card tw-rounded-10 tw-flex-grow-0 tw-flex-shrink-0 tw-border-2 border-box tw-px-4 tw-mb-6 tw-mr-6 tw-cursor-pointer tw-bg-white transition tw-shadow-app-sm hover:tw-shadow-app-lg"
              @click="createAccountType = accountTypes.staff"
            >
              <div
                class="bg-pattern-staff tw-w-full"
                style="height: 90px;"
              ></div>
              <h4 class="font-title tw-text-2xl tw-font-medium tw-text-center tw-leading-9 tw-mt-10">Staff</h4>
            </BaseCard>

            <!-- CORPORATE -->
            <router-link
              tag="div"
              :to="{
                name: 'createCompany',
              }"
            >
              <BaseCard
                style="border-color: #D7B9D5;"
                class="create-account-card tw-rounded-10 tw-flex-grow-0 tw-flex-shrink-0 tw-border-2 border-box tw-px-4 tw-mb-6 tw-mr-6 tw-cursor-pointer tw-bg-white transition tw-shadow-app-sm hover:tw-shadow-app-lg"
              >
                <div
                  class="bg-pattern-corporate tw-w-full"
                  style="height: 90px;"
                ></div>
                <h4 class="font-title tw-flex tw-items-center tw-text-2xl tw-font-medium tw-text-center tw-leading-9 tw-mt-10">
                  Company
                  <svg version="1.1" class="tw-w-6 tw-h-6 tw-ml-2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                    <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
                      s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
                      l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
                  </svg>
                </h4>
              </BaseCard>
            </router-link>

            <!-- SUPER ADMIN -->
            <!-- <BaseCard
              style="border-color: #98C9A3;"
              class="create-account-card tw-rounded-10 tw-flex-grow-0 tw-flex-shrink-0 tw-border-2 border-box tw-px-4 tw-mb-6 tw-cursor-pointer tw-bg-white transition tw-shadow-app-sm hover:tw-shadow-app-lg"
              @click="createAccountType = accountTypes.superAdmin"
            >

              <div
                class="bg-pattern-app tw-w-full"
                style="height: 90px;"
              ></div>
              <h4 class="font-title tw-text-2xl tw-font-medium tw-text-center tw-leading-9 tw-mt-10">Super Admin</h4>

            </BaseCard> -->
          </template>

        </div>

        <CreateAccount
          v-else-if="createAccountType && createAccountType !== accountTypes.corporate"
          class="tw-pb-16 tw-mx-auto tw-text-center"
          btn-class="tw-border-none tw-bg-app-deep-blue tw-py-3 tw-px-6 tw-text-white"
          :account-type="createAccountType"
          @create-success="createAccountType = undefined"
        />
      </transition>
    </portal>

    <div class="app-view-grid px-body tw-py-4">
      <div class="app-view-grid__col-74">
        <!-- FILTER COMPONENT -->
        <BaseCard
          :class="[
            'tw-border-t-2 tw-border-app-white-87 transition-slow',
            { 'tw-border tw-border-t tw-border-app-deep-blue tw-rounded-10 tw-shadow-app-lg': filteredAccounts.length === 0 && !hasFiltered }
          ]"
        >
          <div class="tw-flex tw-flex-wrap tw-py-2 tw-px-4 tw-items-center ">
            <div class="tw-inline-flex tw-items-center tw-flex-shrink-0 tw-pr-8">
              <!--  -->
              <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
                  c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
                  l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
              </svg>
              <!-- <p class="leading-19 tw-ml-2">Filter</p> -->
            </div>
            <div class="tw-flex-grow tw-relative">
              <div class="tw-flex tw-flex-no-wrap tw-items-center tw-mb-4 tw-relative">
                <v-select
                  placeholder="Type"
                  class="my-select tw-w-32 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
                  v-model="filterConfig.type"
                  :value="filterConfig.type"
                  :options="filterOptionsAccTypes"
                  searchable
                  filterable
                  :clearable="false"
                />
                <v-select
                  placeholder="State"
                  class="my-select tw-w-32 tw-ml-4 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
                  v-model="filterConfig.state"
                  :value="filterConfig.state"
                  :options="filterOptionsAccStates"
                  searchable
                  filterable
                  :clearable="false"
                />
                <v-select
                  placeholder="Company"
                  class="my-select tw-w-32 tw-ml-4 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
                  v-model="filterConfig.company"
                  :value="filterConfig.company"
                  :options="filterConfig.companies"
                  :reduce="option => option.id"
                  searchable
                  filterable
                  :clearable="false"
                  v-if="isSuperAdmin"
                />
              </div>

              <BaseInput
                v-model="filterConfig.search"
                type="text"
                placeholder="Search"
                input-class="tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-w-full"
              />
            </div>
          </div>

          <!-- <template v-if="hasFiltered && filteredAccounts.length > 0">
            <div
              :class="[
                'tw-flex tw-flex-wrap tw-items-center tw-w-full tw-py-2 tw-px-4 tw-mt-2 tw-bg-app-black-11 tw-rounded-b-3',
                { 'tw-rounded-b-10': filteredAccounts.length === 0 && !hasFiltered }
              ]"
            >
              <div class="tw-ml-6 tw-pl-8"></div>
              <div class="tw-inline-flex tw-pr-4">
                Sort By:
              </div>
              <div>
                <v-select
                  class="my-select tw-w-32 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
                  v-model="sortBy"
                  :value="sortBy"
                  :options="filterOptionsAccTypes"
                  searchable
                  filterable
                  :clearable="false"
                />
              </div>
            </div>
          </template> -->
        </BaseCard>

        <!-- ACCOUNTS -->
        <transition name="fade" mode="out-in" appear>

          <div v-if="errorAccounts" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorAccounts"></p>
          </div>

          <ContentLoadingFullWidth
            v-else-if="loadingAccounts"
            class="tw-py-16 -tw-max-w-list tw-mx-auto"
            :list-length="4"
          />

          <template v-else>

            <div v-if="filteredAccounts.length === 0 && hasFiltered" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">There are 0 {{ filterConfig.type }} accounts</p>
            </div>

            <div v-else-if="filteredAccounts.length === 0" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">Choose an account type at the filter bar to view accounts</p>
            </div>

            <AccountsList
              v-else
              class="tw-max-w-list tw-mx-auto tw-mt-12"
              :list="filteredAccounts"
            />

          </template>

        </transition>
      </div>
      <div></div>
    </div>
  </AppView>
</template>

<script>
import { mapState } from 'vuex';
import _debounce from 'lodash/debounce';

import filterMixin from '@/mixins/filter';
import reportMixin from '@/mixins/report';

import CreateAccount from '@/views/Accounts/_partials/CreateAccount.vue';
import BaseInput from '@/components/BaseInput.vue';
import AccountsList from '@/views/_partials/AccountsList.vue';
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';

export default {
  name: 'AccountsView',
  components: {
    CreateAccount,
    BaseInput,
    AccountsList,
    ContentLoadingFullWidth,
  },
  mixins: [filterMixin, reportMixin],
  data() {
    return {
      accounts: [],
      loadingAccounts: true,
      errorAccounts: undefined,
      loadingForm: true,
      createAccountType: undefined,
      filterConfig: {
        search: '',
        searchAt: [
          'email',
          'name',
          'middle',
          'last_name',
          'type',
        ],
        type: '',
        state: '',
        company: undefined,
        companies: [],
        dataName: 'accounts',
      },
      sortBy: undefined,
      sortByOptions: [
        {
          id: 'createAt',
          label: '',
        },
      ],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'filterConfig.type': async function () {
      if (!this.filterConfig.state.length) return;
      // reset search bar value
      this.filterConfig.search = '';
      // xhr filter
      this.loadingAccounts = true;
      await this.doFilter();
      this.loadingAccounts = false;
    },
    // eslint-disable-next-line func-names
    'filterConfig.state': async function () {
      if (!this.filterConfig.type.length) return;
      // reset search bar value
      this.filterConfig.search = '';
      // xhr filter
      this.loadingAccounts = true;
      await this.doFilter();
      this.loadingAccounts = false;
    },
    // eslint-disable-next-line func-names
    'filterConfig.company': async function () {
      if (!this.filterConfig.company) return;
      // reset search bar value
      this.filterConfig.search = '';
      // xhr filter
      this.loadingAccounts = true;
      await this.doFilter();
      this.loadingAccounts = false;
    },
    modalState(open) {
      // if closed
      if (!open) {
        setTimeout(() => {
          this.createAccountType = undefined;
        }, 500);
      }
    },
  },
  computed: {
    ...mapState({
      modalState: (state) => state.Ui.modal.openModal,
      accountTypes: (state) => state.User.accountTypes,
    }),
    filterOptionsAccTypes() {
      const types = Object.keys(this.$store.state.User.accountTypes || {});

      if (!this.isSuperAdmin) {
        const index = types.indexOf('superAdmin');
        if (index >= 0) {
          types.splice(index, 1);
        }
      }

      const indexCop = types.indexOf('corporate');
      if (indexCop >= 0) {
        types.splice(indexCop, 1);
      }

      return types;
    },
    filterOptionsAccStates() {
      return Object.keys(this.$store.state.Accounts.accountStates || {});
    },
    filteredAccounts() {
      return this.filteredList;
    },
    hasFiltered() {
      return this.filterConfig.type.length > 0
        && this.filterConfig.state.length > 0
        && !!this.filterConfig.company;
    },
  },
  methods: {
    openModal() {
      this.$store.commit('openModal', [true, 'full-screen']);
    },
    clickCreateAccountCard(AccountType) {
      // redirect to CreateAccount View
      // [UPDATE] CREATE FROM THE SAME MODAL!
      //      Load component to the modal
      console.log(AccountType);
    },

    async getAccounts(accountType, accountState, companyId) {
      if (!accountType || !accountState || !companyId) {
        console.warn('accountType, accountState or company is not specified in this.getAccounts()');
      }

      this.errorAccounts = undefined;
      this.loadingAccounts = true;

      const accounts = await this.$store.dispatch('getAccounts', [accountType, accountState, companyId]);

      // console.log(accounts);
      if (!accounts) {
        this.errorAccounts = `Error in finding ${accountState} ${accountType}s accounts.`;
      } else if (Array.isArray(accounts)) {
        this.accounts = accounts;
      }

      await this.$nextTick();
      this.loadingAccounts = false;
    },


    // eslint-disable-next-line func-names
    doFilter: _debounce(async function () {
      if (!this.hasFiltered) return;

      this.errorAccounts = undefined;
      this.loadingAccounts = true;
      await this.$nextTick();
      await this.$nextTick();

      await this.getAccounts(this.filterConfig.type, this.filterConfig.state, this.filterConfig.company);

      await this.$nextTick();
      this.loadingAccounts = false;
    }, 800, { leading: true }),
  },
  async created() {
    this.reportType = 'downloadAccountsList';
    this.loadingAccounts = false;

    if (this.$route.query.create) {
      // prompt create account
      this.createAccountType = this.$route.query.create;
      this.openModal();
    } else {
      if (this.$route.query.state) {
        this.filterConfig.state = this.$route.query.state;
      }
      if (this.$route.query.type) {
        this.filterConfig.type = this.$route.query.type;
      }

      if (this.$route.query.company) {
        this.filterConfig.company = this.$route.query.company;
      }

      try {
        if (this.isSuperAdmin) {
          // load the companies options
          const companies = await this.$store.dispatch('getCompanies');

          if (!companies) {
            throw Error('error in fetching company details');
          }

          this.filterConfig.companies = companies.map((c) => ({
            id: c.id,
            label: c.company,
          }));
        } else {
          const myCompany = await this.$store.dispatch('myCompany');

          if (!myCompany) {
            throw Error('error in fetching company details');
          }

          this.filterConfig.company = myCompany.id;
        }
      } catch (error) {
        console.error(error);
        this.$toasted.global.appError();
      }


      await this.$nextTick();
      await this.$nextTick();
    }
  },
};
</script>

<style lang="scss" scoped>
.create-account-card {
  width: 200px;
  height: 240px;
  // border-color: #90A8C3;
}
</style>
