<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          type="back"
          :show-back-button="isSuperAdmin"
          :prev-route="{ name: 'companies' }"
          :title="company.company || 'fetching company...'"
          :title-class="[
            { 'tw-ml-8': isSuperAdmin },
            { 'ml-body': !isSuperAdmin },
            { 'opacity-78 font-title tw-text-xl tw-tracking-wider tw-leading-6': !loadingCompany },
            { 'opacity-31 tw-italic tw-lowercase': loadingCompany },
          ]"
        >
          <template v-slot:title-left v-if="!loadingCompany">
            <UserPhoto
              :photo="company.logo"
              :user-name="{
                name: company.company,
              }"
              logo
              photo-size="tw-h-12 tw-w-12"
              :class="[
                'tw-mr-6 tw-my-1 tw-rounded-10--force --force-children tw-bg-app-white-87',
                { 'tw-shadow-app-sm': !(company.logo || '').length }
              ]"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">

        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">

              <!-- COMPANY LICENSE DETAILS -->
              <MyLicense
                class="tw-rounded-32 tw-border-t-2 tw-border-app-white-87 tw-p-8 tw-shadow-app tw-mb-12 tw-mt-6 transition-slow"
                :company-ref="company"
              />

              <!-- COMPANY OPTIONS -->

              <div class="tw-flex stacked-grid tw-mt-16">
                <BaseCard
                  class="tw-w-1/2- stacked-grid--stack-two tw-border-t-2 tw-border-app-white-87 tw-shadow-app hover:tw-shadow-app-lg hover:tw-border-none transition tw-py-6 tw-px-4 tw-cursor-pointer tw-flex tw-items-center"
                  @click="routeTo({ name: 'customizeCompany', params: { id: company.id } })"
                >
                  <img data-src="/img/customize-experience.png" src="/img/customize-experience.png" class="tw-object-cover tw-block img-width-100" async>
                  <h5 class="tw-text-2xl tw-ml-6 font-title tw-text-app-yellow tw-leading-tight">
                    Customize your mentorship experience
                  </h5>
                </BaseCard>

                <BaseCard
                  class="tw-w-1/2- stacked-grid--stack-two tw-border-t-2 tw-border-app-white-87 tw-shadow-app hover:tw-shadow-app-lg hover:tw-border-none transition tw-py-6 tw-px-4 tw-cursor-pointer tw-flex tw-items-center"
                  @click="routeTo({ name: 'editCompanyGoals', params: { id: company.id } })"
                >
                  <img data-src="/img/edit-goals.png" src="/img/edit-goals.png" class="tw-object-cover tw-block img-width-100" async>
                  <h5 class="tw-text-2xl tw-ml-6 font-title tw-text-app-yellow tw-leading-tight">
                    Edit Goals
                  </h5>
                </BaseCard>
              </div>

            </div>
          </div>

        </transition>

      </div>

    </transition>

  </AppView>
</template>

<script>
import MyLicense from '@/views/_partials/MyLicense.vue';
import UserPhoto from '@/components/UserPhoto.vue';

import companyMixin from '@/mixins/company';
import routerMixin from '@/mixins/router';

export default {
  name: 'CompanyView',
  components: {
    MyLicense,
    UserPhoto,
  },
  mixins: [companyMixin, routerMixin],
  props: ['id'],
  async created() {
    // get company
    await this.getCompany();

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await this.$nextTick();
    await this.$nextTick();

    this.loadingCompany = false;
    return true;
  },
};
</script>

<style>
.img-width-100 {
  width: 100px;
  height: 100px;
}
</style>
