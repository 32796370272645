<template>
  <form
    class="tw-max-w-full md:tw-w-5/6 tw-mx-auto tw-flex-shrink-0 tw-justify-around tw-mb-20 auth-card tw-flex tw-flex-wrap"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full">
      <h3 class="font-title tw-text-2xl tw-text-app-deep-blue tw-text-center">Create a Match</h3>
    </div>

    <div class="tw-w-1/3 tw-pr-6">

      <transition name="fade" mode="out-in" appear>
        <div v-if="errorForm" class="tw-mt-12">
          <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorForm"></p>
        </div>

        <ContentLoadingFullWidth
          v-else-if="loadingForm"
          class="tw-py-16 -tw-max-w-list tw-mx-auto"
          :list-length="8"
        />

        <template v-else-if="!this.company && isSuperAdmin">
          <!-- select a company -->
          <BaseCard class="tw-p-4 tw-bg-white tw-h-64">
            <h4 class="tw-mb-4">Please select a company</h4>
            <v-select
              placeholder="Company"
              class="my-select tw-w-32 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm"
              v-model="company"
              :value="company"
              :options="companies"
              :reduce="option => option.id"
              searchable
              filterable
              :clearable="false"
            />
          </BaseCard>
        </template>

        <div v-else>

          <div class="inline-flex tw-ml-auto tw-my-4">
            <template v-if="selectedMentee">
              <!-- SELECTED MENTEE -->
              <div class="tw-px-4 tw-py-4 tw-shadow-app tw-bg-white transition tw-rounded-10 tw-border tw-border-app-deep-blue">
                <div class="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-w-full ">
                  <h5 class="tw-text-xs opacity-54 tw-leading-tight tw-font-bold tw-uppercase tw-text-app-deep-blue">Selected Mentee</h5>
                  <button
                    type="button"
                    @click.stop.prevent="matchForm.mentee = undefined"
                    title="clear selected mentee"
                    class="tw-cursor-pointer"
                  >
                    <svg class="tw-w-4 tw-h-4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                        L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                        s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
                    </svg>
                  </button>
                </div>
                <div :key="selectedMentee.id" class="tw-flex tw-items-center tw-justify-between">
                  <div class="tw-flex tw-items-center tw-min-w-1/3">
                    <UserPhoto
                      photo-size="tw-w-8 tw-h-8"
                      :user-name="selectedMentee"
                      :photo="selectedMentee.photo"
                      class="tw-mr-4 tw-flex-shrink-0"
                    />
                    <div class="tw-mr-8">
                      <p class="tw-text-base leading-19">
                        {{ selectedMentee.name }} {{ selectedMentee.middle }}
                      </p>
                      <!-- <p class="tw-text-sm tw-font-sans leading-17 tw-uppercase opacity-54 tw-text-app-yellow tw-mt-2">
                        {{ userTypeName(selectedMentee.type) }}
                      </p> -->
                    </div>
                  </div>

                  <p
                    :title="selectedMentee.email"
                    :class="[
                      'tw-text-sm leading-17 tw-py-4 tw-truncate tw-flex-shrink tw-min-w-1/4',
                    ]"
                  >
                    {{ selectedMentee.email }}
                  </p>

                  <!-- <template v-if="listType === 'list'">
                    <p class="tw-text-sm leading-17 tw-py-4 tw-text-red-500 tw-mx-8">
                      ...ACTIONS
                    </p>
                  </template> -->
                </div>
              </div>
              <div style="height: 66px;"></div>

              <div class="tw-w-full divider tw-border-t opacity-78 tw-my-6"></div>
            </template>
          </div>

          <!-- MENTEES -->
          <h4 class="tw-text-base tw-mb-4 tw-text-center opacity-78">Mentees</h4>
          <div v-if="!mentees.length" class="tw-mt-12">
            <!-- Goodies -->
            <p class="tw-py-16 tw-text-base tw-text-center opacity-54">There are 0 mentees</p>
          </div>

          <AccountsList
            v-else
            :list="mentees"
            single-account-type
            searchable
            selectable
            v-model="matchForm.mentee"
          />

        </div>
      </transition>

    </div>

    <div class="tw-flex-shrink tw-flex-grow tw-pt-16">
      <!-- IDK, GOODIES?? -->
      <!-- <p class="tw-text-center">
        Match?
      </p> -->
    </div>

    <div class="tw-w-1/3 tw-pl-6">

      <transition name="fade" mode="out-in" appear>
        <div v-if="errorSuggestions" class="tw-mt-12">
          <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorSuggestions"></p>
        </div>

        <ContentLoadingFullWidth
          v-else-if="loadingSuggestions"
          class="tw-py-16 -tw-max-w-list tw-mx-auto"
          :list-length="8"
        />

        <div v-else-if="!selectedMentee">
          <p class="tw-py-16 opacity-54 tw-text-base tw-text-center">
            Select a mentee to view a list of suggested mentors
          </p>
        </div>

        <div v-else-if="!mentors.length && selectedMentee">
          <p class="tw-py-16 opacity-54 tw-text-base tw-text-center">
            We could not find a mentor for {{ selectedMentee.name }} at the moment.
          </p>
        </div>

        <div v-else>

          <div class="inline-flex tw-ml-auto tw-my-4">
            <template v-if="selectedMentor">
              <!-- SELECTED MENTOR -->
              <div class="tw-px-4 tw-py-4 tw-shadow-app tw-bg-white transition tw-rounded-10 tw-border tw-border-app-deep-blue">
                <div class="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-w-full ">
                  <h5 class="tw-text-xs opacity-54 tw-leading-tight tw-font-bold tw-uppercase tw-text-app-deep-blue">Selected Mentor</h5>
                  <button
                    type="button"
                    @click.stop.prevent="matchForm.mentor = undefined"
                    title="clear selected mentee"
                    class="tw-cursor-pointer"
                  >
                    <svg class="tw-w-4 tw-h-4" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                        L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                        s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
                    </svg>
                  </button>
                </div>
                <div :key="selectedMentor.id" class="tw-flex tw-items-center tw-justify-between">
                  <div class="tw-flex tw-items-center tw-min-w-1/3">
                    <UserPhoto
                      photo-size="tw-w-8 tw-h-8"
                      :user-name="selectedMentor"
                      :photo="selectedMentor.photo"
                      class="tw-mr-4 tw-flex-shrink-0"
                    />
                    <div class="tw-mr-8">
                      <p class="tw-text-base leading-19">
                        {{ selectedMentor.name }} {{ selectedMentor.middle }}
                      </p>
                      <!-- <p class="tw-text-sm tw-font-sans leading-17 tw-uppercase opacity-54 tw-text-app-yellow tw-mt-2">
                        {{ userTypeName(selectedMentor.type) }}
                      </p> -->
                    </div>
                  </div>

                  <p
                    :title="selectedMentor.email"
                    :class="[
                      'tw-text-sm leading-17 tw-py-4 tw-truncate tw-flex-shrink tw-min-w-1/4',
                    ]"
                  >
                    {{ selectedMentor.email }}
                  </p>

                  <!-- <template v-if="listType === 'list'">
                    <p class="tw-text-sm leading-17 tw-py-4 tw-text-red-500 tw-mx-8">
                      ...ACTIONS
                    </p>
                  </template> -->
                </div>
              </div>
              <!-- match button -->
              <BaseButton
                type="submit"
                :class="[
                  'tw-block tw-w-full tw-my-3 tw-py-3 tw-px-8 tw-mx-auto',
                ]"
                :text="btnText"
                :disabled="!canSubmit || btnDisabled"
              />
              <div class="tw-w-full divider tw-border-t opacity-78 tw-my-8"></div>
            </template>
          </div>

          <!-- MENTORS -->
          <h4 class="tw-text-base tw-mb-4 tw-text-center opacity-78">Suggested Mentors</h4>
          <AccountsList
            :list="mentors"
            single-account-type
            searchable
            selectable
            v-model="matchForm.mentor"
          />

        </div>
      </transition>

    </div>

  </form>

</template>

<script>
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';
import AccountsList from '@/views/_partials/AccountsList.vue';
import UserPhoto from '@/components/UserPhoto.vue';

import _compact from 'lodash/compact';

export default {
  name: 'CreateMatch',
  components: {
    ContentLoadingFullWidth,
    AccountsList,
    UserPhoto,
  },
  data() {
    return {
      btnText: 'Create Match',
      btnDisabled: false,

      loadingForm: true,
      errorForm: undefined,

      loadingSuggestions: true,
      errorSuggestions: undefined,

      mentees: [],
      mentors: [],
      matchForm: {
        mentor: undefined,
        mentee: undefined,
        //
      },
      companies: [],
      company: undefined,
      errors: {},
      filterConfig: {
        mentors: {
          dataName: 'mentors',
          search: '',
          searchAt: [
            'email',
            'name',
            'middle',
            'last_name',
          ],
        },
        mentees: {
          dataName: 'mentees',
          search: '',
          searchAt: [
            'email',
            'name',
            'middle',
            'last_name',
          ],
        },
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'matchForm.mentee': function (val) {
      if (!val) {
        this.mentors = [];
        return;
      }
      this.matchForm.mentor = undefined;
      this.getSuggestedMentors();
    },
    // eslint-disable-next-line func-names
    async company() {
      if (!this.company) return;
      // xhr filter
      this.loadingForm = true;
      await this.loadForm();
      this.loadingForm = false;
    },
  },
  computed: {
    canSubmit() {
      return true;
    },
    selectedMentee() {
      if (typeof this.matchForm.mentee === 'object') return this.matchForm.mentee;
      return this.mentees.find((m) => m.id === this.matchForm.mentee);
    },
    selectedMentor() {
      if (typeof this.matchForm.mentor === 'object') return this.matchForm.mentor;
      return this.mentees.find((m) => m.id === this.matchForm.mentee);
    },
  },
  methods: {
    resetForm() {
      this.company = undefined;
      this.matchForm = {
        mentee: undefined,
        mentor: undefined,
      };
    },
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      const data = {
        menteeId: this.matchForm.mentee.id,
        mentorId: this.matchForm.mentor.id,
      };

      this.btnDisabled = true;
      this.btnText = 'working...';

      await this.$store.dispatch('createMatch', data)
        .catch((err) => {
          console.warn('error in creating the match: ', err);
          this.$toasted.global.appError();
          this.btnText = 'create match';
          return false;
        })
        .then((result) => {
          console.log(result);

          this.btnDisabled = false;
          this.btnText = 'create match';

          if (!result) {
            this.$toasted.global.appError({ errorMessage: 'error in creating the match.' });
            return false;
          }

          this.btnDisabled = false;

          this.$toasted.success('Match created successfully!', { duration: 5000 });

          // clear the match form
          this.resetForm();

          return true;
        });
      //

      return true;
    },
    // get mentees
    async loadForm() {
      const errorMessage = 'error in getting mentees';

      await this.$store.dispatch('getAccounts', ['mentee', 'verified', this.company])
        .catch((err) => {
          console.warn('error in getting mentees: ', err);
          this.errorForm = errorMessage;
          this.$toasted.global.appError();
          return false;
        })
        .then((mentees) => {
          // console.log(mentees);
          if (!mentees) {
            this.loadForm = false;
            this.errorForm = errorMessage;
            this.$toasted.global.appError({ errorMessage });
            return false;
          }

          this.mentees = mentees || [];

          this.loadingForm = false;
          this.errorForm = undefined;

          return true;
        });
    },
    // get all mentors from the company
    async getSuggestedMentors() {
      if (!this.selectedMentee) {
        this.$toasted.global.appError({ errorMessage: 'Please select a mentee' });
        return false;
      }

      const errorMessage = 'error in getting mentors';
      this.loadingSuggestions = true;
      this.errorSuggestions = undefined;

      // get all verified mentors in the company
      await this.$store.dispatch('getAccounts', ['mentor', 'verified', this.company])
        .catch((err) => {
          console.warn('error in getting mentors: ', err);
          this.errorSuggestions = errorMessage;
          this.$toasted.global.appError();
          return false;
        })
        .then(async (mentors) => {
          // console.log(mentors);
          if (!mentors) {
            this.loadingSuggestions = false;
            this.errorSuggestions = errorMessage;
            this.$toasted.global.appError({ errorMessage });
            return false;
          }

          // filter already matched mentees out of the list
          const menteeMentors = await this.$store.dispatch('getMenteeMentors', this.matchForm.mentee.id);

          if (!Array.isArray(menteeMentors)) {
            this.loadingSuggestions = false;
            this.errorSuggestions = errorMessage;
            this.$toasted.global.appError({ errorMessage });
            return false;
          }

          console.log(menteeMentors);

          const unmatchedMenteeMentors = (mentors || []).filter((mentor) => {
            if (menteeMentors.find((menteeMentor) => Number(menteeMentor.id) === Number(mentor.id))) {
              return undefined;
            }
            return mentor;
          });

          this.mentors = _compact(unmatchedMenteeMentors);

          this.loadingSuggestions = false;
          this.errorSuggestions = undefined;

          return true;
        });

      //
      return true;
    },

    // get suggested mentors
    // async getSuggestedMentors() {
    //   if (!this.selectedMentee) {
    //     this.$toasted.global.appError({ errorMessage: 'Please select a mentee' });
    //     return false;
    //   }

    //   const errorMessage = 'error in getting mentors';
    //   this.loadingSuggestions = true;
    //   this.errorSuggestions = undefined;

    //   const mentee = await this.$store.dispatch('getUserData', this.selectedMentee.id);

    //   const data = {
    //     menteeId: mentee.id,
    //     groupId: mentee.menteeProfession.id, // ! DEPR
    //   };

    //   // ? how about get all verified mentors in the company
    //   await this.$store.dispatch('getMatchSuggestions', data)
    //     .catch((err) => {
    //       console.warn('error in getting mentors: ', err);
    //       this.errorSuggestions = errorMessage;
    //       this.$toasted.global.appError();
    //       return false;
    //     })
    //     .then((mentors) => {
    //       // console.log(mentors);
    //       if (!mentors) {
    //         this.loadingSuggestions = false;
    //         this.errorSuggestions = errorMessage;
    //         this.$toasted.global.appError({ errorMessage });
    //         return false;
    //       }

    //       this.mentors = mentors || [];

    //       this.loadingSuggestions = false;
    //       this.errorSuggestions = undefined;

    //       return true;
    //     });

    //   //
    //   return true;
    // },
  },
  async created() {
    this.errors = { ...this.matchForm };
    this.loadingForm = false;

    // await this.loadForm();

    try {
      if (this.isSuperAdmin) {
        // load the companies options
        const companies = await this.$store.dispatch('getCompanies');

        if (!companies) {
          throw Error('error in fetching company details');
        }

        this.companies = companies.map((c) => ({
          id: c.id,
          label: c.company,
        }));
      } else {
        const myCompany = await this.$store.dispatch('myCompany');

        if (!myCompany) {
          throw Error('error in fetching company details');
        }

        this.company = myCompany.id;
      }
    } catch (error) {
      console.error(error);
      this.$toasted.global.appError();
    }

    await this.$nextTick();

    this.loadingForm = false;
    this.loadingSuggestions = false;
  },
};
</script>

<style>

</style>
