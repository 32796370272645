<template>
  <div>
    <transition name="fade-delay" mode="out-in" appear>
      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <template v-else>
        <!-- CONTACT DETAILS -->
        <div class="tw-flex tw-group">
          <div class="tw-flex-shrink tw-max-w-md tw-pb-8">
            <!-- COMPANY CONTACTS -->
            <p class="tw-text-sm leading-17 tw-font-bold opacity-54 tw-mb-3 tw-uppercase">
              Contacts
            </p>

            <a
              :href="`mailto: ${myCompany.email}`"
              class="tw-text-black tw-text-base leading-19 tw-underline tw-truncate tw-block"
            >
              {{myCompany.email}}
            </a>
            <a
              :href="`tel: ${myCompany.phoneNumber}`"
              class="tw-text-black tw-mt-2 tw-text-base leading-19 tw-underline tw-truncate tw-block"
            >
              {{myCompany.phoneNumber}}
            </a>
            <p class="tw-text-black tw-mt-2 tw-text-base leading-19 tw-block">
              Secret: <span class="opacity-54">{{myCompany.secret || '# # # #'}}</span>
            </p>
          </div>

          <div class="tw-ml-12 tw-pl-12 tw-pr-4 tw-border-l tw-border-app-black-11 tw-pb-4 tw-flex-grow">
            <!-- COMPANY ADMINS ADMINS -->
            <p class="tw-text-sm leading-17 tw-font-bold opacity-54 tw-mb-3 tw-uppercase">
              Admins
            </p>

            <transition name="fade-delay" mode="out-in" appear>

              <ContentLoadingTopUsers v-if="loadingCompany" class="tw-w-full"/>

              <div v-else-if="!staffs.length">
                <p class="tw-text-sm opacity-31 tw-mb-3">
                  There are 0 staff accounts
                </p>
                <BaseButton
                  text="Create staff account"
                  class="group-hover:tw-opacity-100 opacity-31 transition"
                  :to="{ name: 'accounts', query: { create: staffAccountType } }"
                />
              </div>

              <div v-else class="tw-inline-flex tw--ml-2">
                <template v-for="user in staffs">
                  <div
                    :key="user.id"
                    class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-5 tw-pr-5 tw-border-r tw-border-gray-200"
                  >
                    <UserVertical
                      class="tw-px-2 tw-py-1 tw-text-left transition-slow"
                      :title="`staff: ${user.name} ${user.middle || user.last_name} - ${user.email}`"
                      :user="user"
                      userPhotoRadius="tw-rounded-3--force"
                      userPhotoSize="tw-w-8 tw-h-8"
                      userNameFontClass="tw-text-xs leading-15"
                    />

                    <p
                      v-if="!Number(user.status)"
                      class="tw-text-xs tw-text-red-500 tw-px-2 opacity-87 tw-font-bold tw-tracking-wider"
                    >
                      unverified
                    </p>
                  </div>
                </template>
              </div>

            </transition>
          </div>
        </div>
      </template>

    </transition>

    <!-- LICENSE DETAILS -->
  </div>
</template>

<script>
import ContentLoadingTopUsers from '@/components/ContentLoadingTopUsers.vue';
import companyMixin from '@/mixins/company';
import UserVertical from '@/components/UserVertical.vue';

export default {
  name: 'MyLicense',
  components: {
    ContentLoadingTopUsers,
    UserVertical,
  },
  mixins: [companyMixin],
  props: {
    companyRef: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      myCompany: {},
      staffs: [],
    };
  },
  watch: {
    companyRef() {
      this.loadingCompany = true;
      this.loadMyCompany();
    },
  },
  computed: {
    staffAccountType() {
      return this.$store.state.User.accountTypes.staff;
    },
  },
  methods: {
    async getCompanyStaffs() {
      const staffs = await Promise.all([
        this.$store.dispatch('getAccounts', ['staff', 'verified', this.myCompany.id]),
        this.$store.dispatch('getAccounts', ['staff', 'unverified', this.myCompany.id]),
      ]);

      this.staffs = [
        ...(staffs[0] || []),
        ...(staffs[1] || []),
      ];
    },
    async loadMyCompany() {
      this.loadingCompany = true;
      this.errorCompany = undefined;

      // console.log(typeof this.companyRef, this.companyRef);
      if (typeof this.companyRef === 'object') {
        this.myCompany = { ...this.companyRef };
        // get company details
        this.loadingCompany = false;
        // load company's staff admins
        await this.getCompanyStaffs();
        return true;
      }

      //
      const company = await this.$store.dispatch('myCompany');

      if (company) {
        this.myCompany = { ...company };

        // load company's staff admins
        await this.getCompanyStaffs();
      } else if (company === false) {
        this.errorCompany = 'Error in loading license.';
      }

      this.loadingCompany = false;

      return true;
    },
  },
  async created() {
    await this.loadMyCompany();

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      return;
    }

    this.loadingCompany = false;
  },
};
</script>

<style>

</style>
