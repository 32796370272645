<template>
  <div>
    <div class="tw-h-8 tw-block"></div>
    <h1 class="tw-text-4xl font-title">{{ title }}</h1>
    <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6>
    <div
      :class="[
        'tw-flex tw-flex-wrap tw-py-16',
        { '--loading-data': pageIsLoading }
      ]"
    >
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label=""
          name="fname"
          type="text"
          placeholder="First name"
          input-classes="tw-p-3"
          input-type=""
          v-model="form.fname"
          :error="errors.fname"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label=""
          name="sname"
          type="text"
          placeholder="Second name"
          input-classes="tw-p-3"
          v-model="form.sname"
          :error="errors.sname"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label=""
          name="email"
          type="email"
          placeholder="email address"
          input-classes="tw-p-3"
          v-model="form.email"
          :error="errors.email"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label=""
          name="password"
          type="password"
          input-classes="tw-p-3"
          placeholder="Password"
          v-model="form.password"
          :error="errors.password"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label=""
          name="confirmpassword"
          type="password"
          input-classes="tw-p-3"
          placeholder="Confirm password"
          v-model="form.confirmPassword"
          :error="errors.confirmPassword"
        />
      </div>
      <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-opacity-0">2. Personal info</h2>
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label="Phone number"
          name="phone"
          type="number"
          placeholder="07********"
          instruct="Use the pattern 07********"
          input-classes="tw-p-3"
          v-model="form.phone"
          :error="errors.phone"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Gender"
          name="gender"
          nameKey="gender"
          placeholder="Gender"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200 tw-text-left"
          v-model="form.gender"
          :options="genderList"
          :reduce="gender => gender.id"
          :selected="form.gender"
          :clearable="false"
          :error="errors.gender"
        />
      </div>
      <!-- <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-opacity-0">2. Personal info</h2>
      </div> -->
      <!-- <div class="tw-w-full md:tw-w-1/2 tw-mb-1 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="County"
          name="county"
          instruct="Type to find your county"
          nameKey="county_name"
          placeholder="County"
          v-model="form.county"
          :options="countyList"
          :reduce="county => county.id"
          :selected="county"
          :clearable="false"
          :error="errors.county"
          filterable
          searchable
        />
      </div> -->
      <!-- <div class="tw-w-full md:tw-w-1/2 tw-flex-shrink-0 tw-px-1 tw-mb-4">
        <SelectGroup
          label="Country"
          name="country"
          instruct="Type to find your country"
          nameKey="country_name"
          placeholder="Country"
          v-model="form.country"
          :error="errors.country"
          :options="countryList"
          :reduce="country => country.id"
          :selected="country"
          :clearable="false"
          filterable
          searchable
        />
      </div> -->
    </div>
  </div>
    <!-- <div class="tw-h-32 tw-block tw-flex-shrink-0"></div> -->
</template>

<script>
/* eslint-disable func-names */
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import { apiGet } from '@/modules/apiHelper';
import form from '@/modules/formHelper';

export default {
  name: 'UserSignUp',
  components: {
    InputGroup,
    SelectGroup,
  },
  props: {
    title: {
      type: String,
      default: 'Sign Up',
    },
  },
  data() {
    return {
      pageIsLoading: true,
      form: {
        fname: undefined,
        sname: undefined,
        email: undefined,
        password: undefined,
        confirmPassword: undefined,
        phone: undefined,
        gender: undefined,
        county: undefined,
        country: undefined,
      },
      errors: {},
      countyList: [],
      countryList: [],
      genderList: [],
    };
  },
  watch: {
    form: {
      async handler(data) {
        await this.$nextTick();
        this.$emit('input', data);
      },
      deep: true,
    },
    // #region text watchers
    'form.fname': function (val) {
      if (!form.isValidTextInput(val)) {
        this.errors.fname = 'invalid First Name';
      } else {
        this.errors.fname = undefined;
      }
    },
    'form.sname': function (val) {
      if (!form.isValidTextInput(val)) {
        this.errors.sname = 'invalid surname';
      } else {
        this.errors.sname = undefined;
      }
    },
    'form.mname': function (val) {
      if (!form.isValidTextInput(val)) {
        this.errors.mname = 'invalid Middle Name';
      } else {
        this.errors.mname = undefined;
      }
    },
    'form.address': function (val) {
      if (!form.isValidTextInput(val)) {
        this.errors.address = 'invalid address';
      } else {
        this.errors.address = undefined;
      }
    },
    // #region number watchers
    'form.phone': function (val) {
      if (!form.isValidNumber(val)) {
        this.errors.phone = 'invalid Phone number. Make sure it follows the pattern 07********';
      } else {
        this.errors.phone = undefined;
      }
    },
    // #region email watchers
    'form.email': function (val) {
      if (!form.isValidEmail(val)) {
        this.errors.email = 'invalid email address';
      } else {
        this.errors.email = undefined;
      }
    },
    // #region select watchers
    'form.gender': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.gender = 'Gender is required';
      } else {
        this.errors.gender = undefined;
      }
    },
    'form.county': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.county = 'County is required';
      } else {
        this.errors.county = undefined;
      }
    },
    'form.country': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.country = 'Country is required';
      } else {
        this.errors.country = undefined;
      }
    },
    // #region passwords
    'form.password': function (val) {
      if (!form.isValidTextInput(val, true, form.passwordRegex)) {
        this.errors.password = 'Password must be atleast 8 characters long';
      } else {
        this.errors.password = undefined;
      }
    },
    'form.confirmPassword': function (val) {
      if (!form.passwordsMatch(this.form.password, val)) {
        this.errors.confirmPassword = 'Password does not match';
      } else {
        this.errors.confirmPassword = undefined;
      }
    },
  },
  mounted() {
    const requests = [
      ['gender', 'genderList'],
      ['users', 'countryList'],
      ['county', 'countyList'],
    ];
    let requestsErrorCount = 0;

    Promise.all([
      apiGet('gender', 5),
      // apiGet('countries', 5),
      // apiGet('county', 5),
    ])
      .catch((err) => {
        console.warn(err);
        this.$toasted.global.appError({ duration: 10000 });
        this.pageIsLoading = true;
      })
      .then((res) => {
        if (!res) return;
        res.forEach(({ data, error }, i) => {
          if (error) {
            console.error('error in fetching... ', res[i]);
            return;
          }
          if (!data[requests[i][0]]) {
            console.error('data not found for \'', `${requests[i]}'`);
            requestsErrorCount += 1;
            return;
          }

          this[requests[i][1]] = data[requests[i][0]];
        });

        if (requestsErrorCount === 0) {
          this.pageIsLoading = false;
        }
      });
  },
  created() {
    // set data errors
    this.errors = { ...this.errors, ...this.form };
  },
};
</script>

<style>

</style>
