var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.searchable)?[_c('BaseCard',{class:[
        'tw-flex tw-flex-no-wrap tw-mb-8 tw-py-2 tw-px-4 tw-items-center tw-bg-white transition-slow' ]},[_c('div',{staticClass:"tw-inline-flex tw-items-center"},[_c('svg',{staticClass:"tw-w-6 tw-h-6",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M71.1,64.3L54.2,51.4c3.9-5.1,6.1-11.2,6.1-18.1C60.3,16.7,46.9,3,30,3S0,16.7,0,33.3s13.4,30.3,30.3,30.3\n            c8.1,0,15.4-3.2,20.8-8.3l17.1,13.2c0.5,0.2,1,0.5,1.5,0.5c0.7,0,1.5-0.2,2-1C72.3,66.8,72.1,65.3,71.1,64.3z M30,58.7\n            c-13.9,0-25.4-11.2-25.4-25.4S15.9,7.9,30,7.9c13.9,0,25.4,11.2,25.4,25.4S44,58.7,30,58.7z"}})])]),_c('BaseInput',{attrs:{"type":"text","placeholder":"Search","input-class":"tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"},model:{value:(_vm.filterConfig.search),callback:function ($$v) {_vm.$set(_vm.filterConfig, "search", $$v)},expression:"filterConfig.search"}})],1)]:_vm._e(),_c('ViewMore',{staticClass:"tw-max-w-list tw-mx-auto",attrs:{"list":_vm.filteredAccounts,"limit":12},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var user = ref.item;
      var index = ref.index;
return [_c('div',{key:user.id,class:[
          'tw-flex tw-items-center tw-justify-between tw--mx-4 tw-px-4 tw-py-4 hover:tw-bg-app-white-87 hover:tw-shadow-app transition  tw-group',
          { 'tw-bg-app-white-32': (((index % 2) === 0)) },
          { 'tw-cursor-pointer': _vm.listType === 'selectable' }
        ],on:{"click":function($event){$event.stopPropagation();return _vm.itemClick(user)}}},[_c('div',{class:[
            'tw-flex tw-items-center tw-group',
            { 'tw-w-1/2': _vm.listType === 'selectable' },
            { 'tw-w-1/3': _vm.listType === 'list' } ]},[_c('UserPhoto',{staticClass:"tw-mr-8 tw-flex-shrink-0",attrs:{"photo-size":"tw-w-12 tw-h-12","user-name":user,"photo":user.photo}}),_c('div',{staticClass:"tw-mr-8"},[_c('p',{staticClass:"tw-text-base leading-19"},[_vm._v(" "+_vm._s(user.name)+" "+_vm._s(user.middle)+" ")]),(!_vm.singleAccountType)?_c('p',{staticClass:"tw-text-sm tw-font-sans leading-17 tw-uppercase opacity-54 tw-text-app-yellow tw-mt-2"},[_vm._v(" "+_vm._s(_vm.userTypeName(user.type))+" ")]):_vm._e()])],1),_c('p',{class:[
            'tw-text-sm leading-17 tw-py-4 tw-truncate tw-flex-shrink tw-w-1/3' ],attrs:{"title":user.email}},[_vm._v(" "+_vm._s(user.email)+" ")]),_vm._t("account-actions",null,{"item":user,"index":index})],2),_c('div',{staticClass:"divider tw-w-full tw-border-t tw-border-app-black-11 opacity-31"})]}}],null,true)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }