<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Matches"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <template v-slot:right-actions>
            <BaseButton
              text="Create a Match"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
              :to="{ name: 'createMatch' }"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <portal to="modal-header">
      <transition name="new" mode="out-in" appear>
        <div style="max-width: 700px;" class="md:tw-w-2/3 tw-mx-auto tw-relative tw-p-4">
          <BaseButton
            class="tw-uppercase tw-absolute tw-rounded-md tw-z-20 hover:tw-bg-gray-300 tw-flex tw-items-center"
            text="Back"
            icon
            :to="{ name: 'matches' }"
          >
            <span
              class="my-btn__icon tw-h-auto"
              style="height: auto;"
              slot="icon"
              v-html="leftNav"
            ></span>
          </BaseButton>
        </div>
      </transition>
    </portal>
    <portal to="modal-body">
      <transition name="new" mode="out-in" appear>
        <CreateMatch v-if="modalState" @create-success="matchCreated" />
      </transition>
    </portal>

    <div class="app-view-grid px-body tw-py-4">
      <div class="app-view-grid__col-74">
        <!-- FILTER COMPONENT -->
        <BaseCard
          :class="[
            'tw-flex tw-flex-wrap tw-py-2 tw-px-4 tw-items-center tw-border-t-2 tw-border-app-white-87 transition-slow',
            { 'tw-border tw-border-t tw-border-app-deep-blue tw-rounded-10 tw-shadow-app-lg': filteredMatches.length === 0 && !hasFiltered }
          ]"
        >
          <div class="tw-inline-flex tw-items-center tw-flex-shrink-0 tw-pr-8">
            <!--  -->
            <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
                c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
                l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
            </svg>
            <!-- <p class="leading-19 tw-ml-2">Filter</p> -->
          </div>
          <div class="tw-flex-grow tw-relative">
            <div class="tw-flex tw-flex-no-wrap tw-items-center tw-mb-4 tw-relative">
              <v-select
                placeholder="State"
                class="my-select tw-w-32 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
                v-model="filterConfig.state"
                :value="filterConfig.state"
                :options="filterOptionsMatchStates"
                searchable
                filterable
                :clearable="false"
              />
              <v-select
                placeholder="Company"
                class="my-select tw-w-32 tw-ml-4 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
                v-model="filterConfig.company"
                :value="filterConfig.company"
                :options="filterConfig.companies"
                :reduce="option => option.id"
                searchable
                filterable
                :clearable="false"
                v-if="isSuperAdmin"
              />
            </div>

            <BaseInput
              v-model="filterConfig.search"
              type="text"
              placeholder="Search"
              input-class="tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-w-full"
            />
          </div>
        </BaseCard>

        <!-- MATCHES -->
        <transition name="fade" mode="out-in" appear>

          <div v-if="errorMatches" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorMatches"></p>
          </div>

          <ContentLoadingFullWidth
            v-else-if="loadingMatches"
            class="tw-py-16 -tw-max-w-list tw-mx-auto"
            :list-length="4"
          />

          <template v-else>

            <div v-if="filteredMatches.length === 0 && hasFiltered" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">There are 0 matches</p>
            </div>

            <div v-else-if="filteredMatches.length === 0" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">Choose a company at the search bar to view matches</p>
            </div>

            <MatchesList
              class="tw-mt-12"
              v-else
              :list="filteredMatches"
              @update="doFilter()"
            />

          </template>

        </transition>
      </div>
      <div></div>
    </div>
  </AppView>
</template>

<script>
import _debounce from 'lodash/debounce';
import filterMixin from '@/mixins/filter';

import CreateMatch from '@/views/Matches/_partials/CreateMatch.vue';
import MatchesList from '@/views/_partials/MatchesList.vue';
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';
import BaseInput from '@/components/BaseInput.vue';
import { mapState } from 'vuex';

export default {
  name: 'MatchesView',
  components: {
    BaseInput,
    CreateMatch,
    MatchesList,
    ContentLoadingFullWidth,
  },
  mixins: [filterMixin],
  data() {
    return {
      matches: [],
      loadingMatches: true,
      errorMatches: undefined,
      loadingForm: true,
      createMatch: undefined,
      filterConfig: {
        search: '', // the search input
        searchAt: [
          ['mentee', 'email'],
          ['mentee', 'name'],
          ['mentee', 'middle'],
          ['mentee', 'last_name'],
          ['mentee', 'type'],
          ['mentor', 'email'],
          ['mentor', 'name'],
          ['mentor', 'middle'],
          ['mentor', 'last_name'],
          ['mentor', 'type'],
        ],
        companies: [], // filter dropdown options
        company: undefined, // selected option
        state: 'active',
        dataName: 'matches', // data being filtered
      },
    };
  },
  watch: {
    $route() {
      this.reactToRoute();
    },
    // eslint-disable-next-line func-names
    'filterConfig.state': async function () {
      if (!this.filterConfig.company.length) return;
      // reset search bar value
      this.filterConfig.search = '';
      // xhr filter
      this.loadingMatches = true;
      await this.doFilter();
      this.loadingMatches = false;
    },
    // eslint-disable-next-line func-names
    'filterConfig.company': async function () {
      if (!this.filterConfig.company) return;
      // reset search bar value
      this.filterConfig.search = '';
      // xhr filter
      this.loadingMatches = true;
      await this.doFilter();
      this.loadingMatches = false;
    },
  },
  computed: {
    ...mapState({
      modalState: (state) => state.Ui.modal.openModal,
    }),
    filterOptionsMatchStates() {
      return Object.keys(this.$store.state.Matches.matchStatesApiUrlActions || {});
    },
    filteredMatches() {
      return this.filteredList;
    },
    hasFiltered() {
      return this.filterConfig.state.length > 0
        && !!this.filterConfig.company;
    },
  },
  methods: {
    reactToRoute() {
      if (this.$route.name === 'createMatch') {
        // open modal
        this.$store.commit('openModal', [true, 'full-screen', false]);
      } else {
        // close modal
        this.$store.commit('openModal', [false]);
      }
    },
    // eslint-disable-next-line no-unused-vars
    async matchCreated(data) {
      //
      this.$toasted.success('Match created! Redirecting you to matches\' list', {
        duration: 4000,
      });

      // close modal
      this.$store.commit('openModal', [false]);

      await this.$nextTick();
      await this.$nextTick();

      // redirect to matches
      this.$router.push({
        name: 'matches',
      });
    },
    async getMatches(matchState, companyId) {
      this.loadingMatches = true;

      const matches = await this.$store.dispatch('getMatches', [matchState, companyId]);

      if (!matches) {
        this.errorMatches = 'Error in finding matches.';
      } else if (Array.isArray(matches)) {
        this.matches = matches;
      }

      await this.$nextTick();
      this.loadingMatches = false;
    },

    // eslint-disable-next-line func-names
    doFilter: _debounce(async function () {
      if (!this.hasFiltered) return;

      this.errorMatches = undefined;
      this.loadingMatches = true;
      await this.$nextTick();
      await this.$nextTick();

      await this.getMatches(this.filterConfig.state, this.filterConfig.company);

      await this.$nextTick();
      this.loadingMatches = false;
    }, 800, { leading: true }),
  },
  async created() {
    await this.reactToRoute();
    this.loadingMatches = false;

    try {
      if (this.isSuperAdmin) {
        // load the companies options
        const companies = await this.$store.dispatch('getCompanies');

        if (!companies) {
          throw Error('error in fetching company details');
        }

        this.filterConfig.companies = companies.map((c) => ({
          id: c.id,
          label: c.company,
        }));
      } else {
        const myCompany = await this.$store.dispatch('myCompany');

        if (!myCompany) {
          throw Error('error in fetching company details');
        }

        this.filterConfig.company = myCompany.id;
      }
    } catch (error) {
      console.error(error);
      this.$toasted.global.appError();
    }
  },
};
</script>

<style>
</style>
