<template>
  <div>
    <!-- searchbar -->
    <template v-if="searchable">
      <BaseCard
        :class="[
          'tw-flex tw-flex-no-wrap tw-mb-8 tw-py-2 tw-px-4 tw-items-center tw-bg-white transition-slow',
        ]"
      >
        <div class="tw-inline-flex tw-items-center">
          <!--  -->
          <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M71.1,64.3L54.2,51.4c3.9-5.1,6.1-11.2,6.1-18.1C60.3,16.7,46.9,3,30,3S0,16.7,0,33.3s13.4,30.3,30.3,30.3
              c8.1,0,15.4-3.2,20.8-8.3l17.1,13.2c0.5,0.2,1,0.5,1.5,0.5c0.7,0,1.5-0.2,2-1C72.3,66.8,72.1,65.3,71.1,64.3z M30,58.7
              c-13.9,0-25.4-11.2-25.4-25.4S15.9,7.9,30,7.9c13.9,0,25.4,11.2,25.4,25.4S44,58.7,30,58.7z"/>
          </svg>
          <!-- <p class="leading-19 tw-ml-2">Search</p> -->
        </div>
        <BaseInput
          v-model="filterConfig.search"
          type="text"
          placeholder="Search"
          input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
        />
      </BaseCard>
    </template>

    <ViewMore
      class="tw-max-w-list tw-mx-auto"
      :list="filteredAccounts"
      :limit="12"
    >

      <template v-slot="{ item: user, index }">
        <!-- - -->
        <div
          :key="user.id"
          :class="[
            'tw-flex tw-items-center tw-justify-between tw--mx-4 tw-px-4 tw-py-4 hover:tw-bg-app-white-87 hover:tw-shadow-app transition  tw-group',
            { 'tw-bg-app-white-32': (((index % 2) === 0)) },
            { 'tw-cursor-pointer': listType === 'selectable' }
          ]"
          @click.stop="itemClick(user)"
        >
          <div :class="[
              'tw-flex tw-items-center tw-group',
              { 'tw-w-1/2': listType === 'selectable' },
              { 'tw-w-1/3': listType === 'list' },
            ]"
          >
            <UserPhoto
              photo-size="tw-w-12 tw-h-12"
              :user-name="user"
              :photo="user.photo"
              class="tw-mr-8 tw-flex-shrink-0"
            />
            <div class="tw-mr-8">
              <p class="tw-text-base leading-19"> {{ user.name }} {{ user.middle }} </p>
              <p v-if="!singleAccountType" class="tw-text-sm tw-font-sans leading-17 tw-uppercase opacity-54 tw-text-app-yellow tw-mt-2"> {{ userTypeName(user.type) }} </p>
            </div>
          </div>

          <p
            :title="user.email"
            :class="[
              'tw-text-sm leading-17 tw-py-4 tw-truncate tw-flex-shrink tw-w-1/3',
            ]"
          >
            {{ user.email }}
          </p>

          <slot name="account-actions" :item="user" :index="index"></slot>

        </div>
        <div class="divider tw-w-full tw-border-t tw-border-app-black-11 opacity-31"></div>
      </template>

    </ViewMore>
  </div>
</template>

<script>
import filterMixin from '@/mixins/filter';
import BaseInput from '@/components/BaseInput.vue';
import UserPhoto from '@/components/UserPhoto.vue';

export default {
  name: 'AccountsList',
  components: {
    BaseInput,
    UserPhoto,
  },
  mixins: [filterMixin],
  props: {
    list: {
      type: Array,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    singleAccountType: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filterConfig: {
        search: '',
        searchAt: [
          'email',
          'name',
          'middle',
          'last_name',
          'type',
        ],
        dataName: 'list',
      },
    };
  },
  computed: {
    listType() {
      if (this.selectable) return 'selectable';

      return 'list';
    },
    filteredAccounts() {
      return this.filteredList;
    },
  },
  methods: {
    itemClick(item) {
      if (!this.selectable) return;
      this.$emit('input', item);
    },
  },
};
</script>

<style>

</style>
